import React, { ReactNode, SetStateAction, Suspense, useEffect, useMemo, useRef } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation, useSearchParams } from 'react-router-dom'
import store from './store'
import './App.css'
import SignUp from './components/signup-login/SignUp'
import Login from './components/signup-login/Login'
import AuthActivate from './components/signup-login/AuthActivate'
import HomeDataEntery from './components/page/HomePage'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { autoLoginAsync } from './store/auth'
import { UnitCreatePage } from './components/data-entry/unit/UnitCreatePage'
import UnitsPage from './components/data-entry/unit/UnitsPage'
import { UnitUpdatePage } from './components/data-entry/unit/UnitUpdatePage'
import { CreateSlide } from './components/data-entry/Slide/CreateSlide'
import SlidesListPage from './components/data-entry/Slide/SlidesListPage'
import { UpdateSlide } from './components/data-entry/Slide/UpdateSlide'
import { CreateCourse } from './components/data-entry/course/CreateCourse'
import { UpdateCourse } from './components/data-entry/course/UpdateCourse'
import { CoursesListPage } from './components/courses/CoursesListPage'
import FilesLibPage from './components/gallery/FilesLibPage'
import NotFound from './components/NotFound'
import HomePage from './components/page/HomePage'
import Slides from './components/slides/Slides'
import ChapterViewPage from './components/chapters/ChapterViewPage'
import PageCourseChaptersView from './components/courses/PageCourseChaptersView'
import { CreateExam } from './components/data-entry/exams/CreateExam'
import { UpdateExam } from './components/data-entry/exams/UpdateExam'
import ExamsPage from './components/data-entry/exams/ExamsPage'
import CourseExamView from './components/exams/CourseExamView'
import SpecialExamView from './components/exams/SpecialExamView'
import AdsPage from './components/data-entry/ad/AdsPage'
import { CreateAd } from './components/data-entry/ad/CreateAd'
import { UpdateAd } from './components/data-entry/ad/UpdateAd'
import UsersPage from './components/data-entry/users/UsersPage'
import { CreateUserPage } from './components/data-entry/users/CreateUserPage'
import { UpdateUserPage } from './components/data-entry/users/UpdateUserPage'
import FeedbackPage from './components/feedback/FeedbackPage'
import { CollectionsListPage } from './components/collections/CollectionsListPage'
import { CreateCollection } from './components/data-entry/collection/CreateCollection'
import { UpdateCollection } from './components/data-entry/collection/UpdateCollection'
import { SubcollectionsListPage } from './components/subcollections/SubcollectionsListPage'
import { CreateSubcollection } from './components/data-entry/subcollection/CreateSubcollection'
import { UpdateSubcollection } from './components/data-entry/subcollection/UpdateSubcollection'
import ContactUs from './components/page/ContactUs'
import IdeaEducation from './components/page/IdeaEducation'
import StudentHomePage from './components/page/StudentHomePage'
import RemedialTeaching from './components/remedial-teaching/RemedialTeaching'
import RemedialTeachingList from './components/remedial-teaching/RemedialTeachingList'
import BeginResetPassword from './components/signup-login/BeginResetPassword'
import ResetPassword from './components/signup-login/ResetPassword'
import { handleYtApiReady } from './utils/ytApiUtils'
import SearchSlidesPage from './components/search/SearchSlides'
import ShowExamResult from './components/exams/ShowExamResult'
import { TopLinearProgress } from './components/TopLinearProgress'
// import TagManager from 'react-gtm-module';
// import { initializeTagManager } from 'react-gtm-ts';
// import { ReactTagManager } from 'react-gtm-ts';
// import ButtonWithGtm from './ButtonWithGtm'
import ReactGA from 'react-ga4';
import InstructionsForUse from './components/page/InstructionsForUse'
import CreateAnnouncement from './components/data-entry/announcement/CreateAnnouncement'
import { UpdateAnnouncement } from './components/data-entry/announcement/UpdateAnnouncement'
import AnnouncementsPage from './components/data-entry/announcement/AnnouncementsPage'
import AnnouncementPopup from './components/data-entry/announcement/AnnouncementPopup'
import SignUpForOther from './components/signup-login/SignUpForOther'
import SelectAccountType from './components/signup-login/SelectAccountType'
import PendingUsersPage from './components/page/PendingUsersPage'
// import ChallengePage from './components/challenge/PageChallenger/ChallengePage'
import QuickAccessCoursesPage from './components/page/QuickAccessCoursesPage'
import CreateCompetitionSchedule from './components/data-entry/competitionSchedule/CreateCompetitionSchedule'
import CreateWinner from './components/data-entry/winners/CreateWinner'
import CompetitionListPage from './components/data-entry/competitionSchedule/CompetitionListPage'
import UpdateCompetitionSchedule from './components/data-entry/competitionSchedule/UpdateCompetitionSchedule'
import WinnersListPage from './components/data-entry/winners/WinnersListPage'
import UpdateWinner from './components/data-entry/winners/UpdateWinner'
// import UserAvatarPage from './components/user-avatars/UserAvatarsPage'








function App() {

  const userMe = useAppSelector((state) => state.auth.userMe)
  const isAutoLogging = useAppSelector((state) => state.auth.isAutoLogging)
  const dispatch = useAppDispatch()

  const isUseEffectRun = useRef(false)

  useEffect(() => {
    if (isUseEffectRun.current) return
    isUseEffectRun.current = true
    dispatch(autoLoginAsync() as any)
    loadYoutubeApiScript()
  }, [])

  // ---googleAnalysis-----------------------------------------------
    useEffect(() => {
      ReactGA.initialize('YE43ZZSJB3');
    }, [])
  // --------------------------------------------googleAnalysis------

  function loadYoutubeApiScript() {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      document.head.prepend(tag)
    }

    // @ts-ignore
    window.onYouTubeIframeAPIReady = function () {
      handleYtApiReady()
    }
  }

  const location = useLocation()
  const [searchParams] = useSearchParams()

  const routes = useMemo(() => {
    let resultRoutes: {
      path: string
      element: ReactNode
    }[] = [
        { path: '/', element: <HomePage /> },
        { path: '/remedial-teaching', element: <RemedialTeaching /> },
        { path: '/ideaeducation', element: <IdeaEducation /> },
        { path: '/student', element: <StudentHomePage /> },
        { path: '/contactus', element: <ContactUs /> },
        { path: '/auth/begin-reset-password', element: <BeginResetPassword /> },
        { path: '/auth/reset-password/:resetToken', element: <ResetPassword /> },
        { path: '/show-special-exam-result', element: <ShowExamResult /> },
        { path: '/exam-generated-by-teacher/student-results', element: <ShowExamResult /> },
        { path: '/instructions-for-use', element: <InstructionsForUse /> },
        { path: '/Quick-Access-courses', element: <QuickAccessCoursesPage /> },
        { path: '/competition-schedule', element: <CompetitionListPage /> },
        { path: '/winners-list-page', element: <WinnersListPage /> },
      ]

    if (!userMe) {
      resultRoutes.push(
        { path: '/select-account-type', element: <SelectAccountType /> },
        { path: '/signup', element: <SignUp /> },
        { path: '/signUp-for-other', element: <SignUpForOther /> },
        { path: '/login', element: <Login /> },
        { path: '/auth/activate/:activationToken', element: <AuthActivate /> },
        { path: '/*', element: <Navigate to={`/login?redirectTo=${location.pathname}`} replace />, }
      )
    } else {
      const redirectTo = searchParams.get('redirectTo')?.startsWith('/') ? searchParams.get('redirectTo')! : '/'

      resultRoutes.push(
        { path: '/chapters/:chapterId/slides', element: <ChapterViewPage /> },
        { path: '/courses/:courseId/chapters', element: <PageCourseChaptersView />, },
        { path: '/courses/:courseId/questions/:questionsNum/exam', element: <CourseExamView /> },
        { path: '/:teacherCourseToken/courses/:courseId/questions/:questionsNum/exam', element: <CourseExamView /> },
        { path: '/exams/:examToken', element: <SpecialExamView /> },
        { path: '/collections', element: <CollectionsListPage /> },
        { path: '/collections/:collectionId/subcollections', element: <SubcollectionsListPage /> },
        { path: '/subcollections/:subcollectionId/courses', element: <CoursesListPage /> },
        { path: '/Quick-Access-courses', element: <QuickAccessCoursesPage /> },
        { path: '/login', element: <Navigate to={redirectTo} replace /> },
        { path: '/signup', element: <Navigate to="/" /> },
        
        
        //  Challenges
        // { path: '/challenge', element: <ChallengePage /> },
        
        
      )
    }
    
    if (userMe?.role === 'admin' || userMe?.role === 'worker') {
      resultRoutes.push(
        { path: '/data-entry/units', element: <UnitsPage /> },
        { path: '/data-entry/units/create', element: <UnitCreatePage /> },
        { path: '/data-entry/units/:unitId/update', element: <UnitUpdatePage /> },
        { path: '/data-entry/units/:unitId/slides', element: <SlidesListPage /> },
        { path: '/data-entry/slides/search', element: <SearchSlidesPage /> },
        { path: '/data-entry/slides/create', element: <CreateSlide /> },
        { path: '/data-entry/slides/:slideId/update', element: <UpdateSlide /> },
        { path: '/data-entry/remedial-teaching-list', element: <RemedialTeachingList /> },

        // --str------------------------------------------------------------------------------
        { path: '/data-entry/courses/create', element: <CreateCourse /> },
        { path: '/data-entry/courses/:courseId/update', element: <UpdateCourse /> },
        { path: '/data-entry/collections/create', element: <CreateCollection /> },
        { path: '/data-entry/collections/:collectionId/update', element: <UpdateCollection /> },
        { path: '/data-entry/subcollections/create', element: <CreateSubcollection /> },
        { path: '/data-entry/subcollections/:subcollectionId/update', element: <UpdateSubcollection /> },
        // --------------------------------------------------------------------------end------
        { path: '/data-entry/feedbacks', element: <FeedbackPage /> },
        { path: '/data-entry/ads', element: <AdsPage /> },
        { path: '/data-entry/ads/create', element: <CreateAd /> },
        { path: '/data-entry/ads/:adId/update', element: <UpdateAd /> },
        { path: '/data-entry/files-lib', element: <FilesLibPage /> },
        { path: '/data-entry/files-lib/:category', element: <FilesLibPage /> },
        { path: '/data-entry/exams', element: <ExamsPage /> },
        { path: '/data-entry/exams/create', element: <CreateExam /> },
        { path: '/data-entry/exams/:examId/update', element: <UpdateExam /> },
        { path: '/data-entry/competition-schedule/create', element: <CreateCompetitionSchedule /> },
        { path: '/data-entry/competition-schedule/:competitionScheduleId/update', element: <UpdateCompetitionSchedule /> },
        { path: '/data-entry/winners/create', element: <CreateWinner /> },
        { path: '/data-entry/winners/:winnerId/update', element: <UpdateWinner /> },

        

        // { path: '/data-entry/announcements', element: <AnnouncementsPage /> },
        { path: '/data-entry/announcements/create', element: <CreateAnnouncement /> },
        { path: '/data-entry/announcements/:announcementId/update', element: <UpdateAnnouncement /> },
        { path: '/data-entry/announcements', element: <AnnouncementsPage /> },


        // { path: '/data-entry/user-avatars', element: <UserAvatarPage /> },
      )
    }

    if (userMe?.role === 'admin') {
      resultRoutes.push(
        { path: '/data-entry/users', element: <UsersPage /> },
        { path: '/data-entry/pending-users', element: <PendingUsersPage /> },
        { path: '/data-entry/users/create', element: <CreateUserPage /> },
        { path: '/data-entry/users/:userId/update', element: <UpdateUserPage /> }
      )
    }


    if (userMe?.role === 'teacher') {
      resultRoutes.push(
        { path: '/exam-generated-by-teacher/student-results', element: <ShowExamResult /> },
      )
    }

    resultRoutes.push({
      path: '*',
      element: <NotFound />,
    })

    return resultRoutes
  }, [userMe])

  if (isAutoLogging) return null

  return (
    <>
      <AnnouncementPopup />

      <Suspense fallback={<TopLinearProgress />}>
        <Routes>
          {routes.map((route, idx) => (
            <Route key={route.path + idx} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>
    </>
  )
}


export default App;


