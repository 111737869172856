/** @jsxImportSource @emotion/react */

import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useAppSelector } from '../../store/hooks';
import { Button } from '@mui/material'
import { css } from '@emotion/react'
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import {
  useCreateExamResultMutation,
} from '../../store/examResultApi';
import { ExamResultFV } from '../../types/examResult';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useGetExamsQuery } from '../../store/examsApi';
import { enqueueSnackbar } from 'notistack';
import { buildGetErrMsg } from '../../utils/buildGetErrMsg';

interface SlidesResultProps {
  percentageResult: number
  isThereBannerAd: boolean
  setIsShowResult: Dispatch<SetStateAction<boolean>>
  navigateToSlide: (slideIdx: number) => void
}


const SUCCESS_MSGS = {
  create: (obj: string) => (
    <p>تم إرسال النتيجة بنجاح</p>
  ),
};
const getErrMsg = buildGetErrMsg();

// --Comp---------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
const SpecialExamResult: FC<SlidesResultProps> = ({ percentageResult, isThereBannerAd, setIsShowResult, navigateToSlide }) => {
  const { control, handleSubmit, reset } = useFormContext<ExamResultFV>()

  const [createExamResult, {isSuccess: isCreating}] = useCreateExamResultMutation()

  // TODO in the future i need get the data from useGetExamQuery, for get it with little bit data
  const { data: resultData } = useGetExamsQuery()
  
  const sendResultA = () => {
    const url = window.location.href;
    let arrUrl = url.split('/exams/');
    const token = arrUrl[1];

    const specificResult = resultData?.find(result => result.token === token);
    const hasTrueResult = specificResult ? (specificResult.sendResult === true) : false;
    return hasTrueResult;
  }
  // --------------------------------------------------------------------------------
  // I need all the items I use in multiple places
  const url = window.location.href;
  let IsUrlExams = url.split('/exams/') //---[,]
  const token = IsUrlExams[1]




  let showRefreshButton = false;

  const userAnswers = useAppSelector(state => {
    return Object.values(state.slides.userAnswers).map(Object.values).flat(2) as ({ isCorrect: boolean; slideIdx: number })[]
  });

  // const dispatch = useAppDispatch()
  // -----------------------------------------------------------------
  // ------this is only to laren not more-----------------------------
  // -----------------------------------------------------------------
  //   const slides = useMemo(() => {
  //     const results: ({
  //       slideOriginalIdx: number;
  //       result: boolean | null | undefined;
  //     })[] = new Array(userAnswers.length).fill(undefined)

  //     userAnswers.forEach((userAnswer) => {
  //       results[userAnswer.slideIdx] = {
  //         slideOriginalIdx: userAnswer.slideIdx,
  //         result: userAnswer.isCorrect
  //       };
  //     })

  //     if (isThereBannerAd) results[0] = undefined

  //     return results;
  //   }, [userAnswers])
  // -----------------------------------------------------------------
  // -----------------------------------------------------------------
  // -----------------------------------------------------------------


  const slides = useMemo(() => {
    const results: (boolean | null)[] = new Array(userAnswers.length).fill(null)
    userAnswers.forEach(userAnswer => {
      results[userAnswer.slideIdx] = userAnswer.isCorrect;
    })

    return results;
  }, [userAnswers])

  const renderedSlides = slides.map((result, slideIdx) => {
    return (
      <span
        key={slideIdx}
        css={styles.resultBox}
        data-result={result === null ? 'null' : result}
        onClick={() => {
          navigateToSlide(slideIdx)
          setIsShowResult(false);
        }}>
        <LowPriorityIcon css={styles.lowIcon} />
        {/* {slideIdx + (isThereBannerAd ? 0 : 1)} */}
      </span>
    );
  })

  // --------------------------------------------------------
  const refreshButton = <Button variant="contained"
    onClick={() => window.location.reload()}><ThreeSixtyIcon /> استمر للتحسن</Button>
  // --------------------------------------------------------


  let image: React.ReactNode

  if (percentageResult > 95) {
    image = (
      <>
      ⭐⭐ ⭐ ⭐  ⭐
      <p style={{ fontSize: '16px' }}>لقد اجتزت النسبة المطلوبة : يرجى الرجوع خطوة للخلف والانتقال إلى المجموعة التالية من الشرائح</p>
      </>

    )
    
  } else if (percentageResult > 90) {
    image = `⭐\n ⭐ \n ⭐ \n ⭐`
    showRefreshButton = true;

  } else if (percentageResult > 85) {
    image = `⭐\n ⭐ \n ⭐`
    showRefreshButton = true;

  } else if (percentageResult > 50) {
    image = `⭐ \n⭐`
    showRefreshButton = true;
  } else if (percentageResult > 20) {
    image = `⭐`
    showRefreshButton = true;
  } else if (percentageResult < 1) {
    image = <p style={{ fontSize: '16px' }}>أنت لم تقم بالإجابة على أي سؤال</p>
    showRefreshButton = true;
  } else {
    image = null
    showRefreshButton = true;
  }

  // ----------------------------------------------------------
  const formMethods = useForm<ExamResultFV>({
    defaultValues: {},
  });

  const onSubmit = async () => {
    const payload = { 'percentageResult': percentageResult, 'token': token }
    const result = await createExamResult(payload)
    
    if ('data' in result) {
      enqueueSnackbar({
        message: SUCCESS_MSGS['create'](result.data.id),
        variant: 'success'
      })
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }
  

  // ----------------------------------------------------------

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} >
        <div css={styles.result}>
          <div css={styles.imageEmoji}>{image}</div>
          <p style={{ direction: 'ltr' }}>
            Your Result is: %{percentageResult.toFixed(2)}
          </p>
          <hr />

          {sendResultA() && (
            <Button type="submit"
              variant='contained'
              disabled={isCreating}
            > إرسل النتيجة
            </Button>
          )}

          
          {
            !!IsUrlExams ? (<p style={{ fontSize: '16px' }}>
              يجب أن تكون النسبة 95% لكي تجتاز هذا المستوى.
            </p>) : null
          }

          <br />
          {showRefreshButton && refreshButton}
          <br />
          {isThereBannerAd ? renderedSlides.slice(1) : renderedSlides}

        </div>

      </form>
    </FormProvider >
  )
}

export default SpecialExamResult

const styles = {
  result: css({
    fontSize: '50px',
    textAlign: 'center',
  }),
  imageEmoji: css({
    fontSize: '8rem',
  }),
  lowIcon: css({
    color: '#1976d2',
    margin: 'auto',
  }),
  resultBox: css({
    display: 'inline-grid',
    width: '3rem',
    height: '3rem',
    margin: '0.8rem',
    cursor: 'pointer',
    fontSize: '1rem',
    '&[data-result="null"]': {
      border: '0 ',
      backgroundImage: `linear-gradient(
        to top, 
        white 10%,
      )`,
    },
    '&[data-result="true"]': {
      border: '0 ',
      backgroundImage: `linear-gradient(
        to top, 
        green 10%,
        white 50%
      )`,
    },
    '&[data-result="false"]': {
      border: '0 ',
      backgroundImage: `linear-gradient(
        to top, 
        red 10%,
        white 50%
      )`,
    }
  }),
  resultId: css({
    fontSize: '10px'
  })
}
