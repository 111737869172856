import { useGetExamResultsQuery } from '../../store/examResultApi'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LoadingPage from '../LoadingPage'
import { Container } from '@mui/material'
import dayjs from 'dayjs';
import { BgiLink } from '../../UI/BgiLink';

// ---Comp-------------------------------------------------------------
// ----------------------------------------------------------------
const ShowExamResult = () => {
  const isAsTeacher = window.location.pathname.includes('teacher');
  const { isFetching: isFetchingResults, error: resultsError, data: examResults } = useGetExamResultsQuery({ isAsTeacher });
  // const { isLoading, error: usersError, data: users } = useGetUsersQuery()

  let previousDate: string | null = null;

  // ------------------------------------------------------------------

  if (isFetchingResults) return <LoadingPage />;
  if (resultsError) return <div>Error occurred</div>;

  return (
    <Container maxWidth={isAsTeacher ? 'lg' : 'md'}>
      <h1 style={{ textAlign: 'center' }}>نتائج المشاركين في المسابقة</h1>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>lastName</TableCell>
              <TableCell align="center">name</TableCell>
              <TableCell align="center">school</TableCell>
              <TableCell align="center">city</TableCell>
              <TableCell align="center">Result</TableCell>
              {!isAsTeacher && <TableCell align="center">Exam code</TableCell>}
              {isAsTeacher && <TableCell align="center">Course</TableCell>}
              <TableCell align="center">Finished At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>


            {examResults?.map((result, index) => {

              const currentDate = dayjs(result.createdAt).format('YYYY-MM-DD');
              const isDifferentDate = previousDate !== currentDate;
              previousDate = currentDate;
              

              return (
                <>
                  {isDifferentDate && (<h3 style={{ backgroundColor: '#9a9a8b', color: 'whitesmoke', width: '200%' }}>  {currentDate} </h3> )}
                  <TableRow
                    key={result.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor: index % 2 == 0 ? 'beige' : 'gray.200',
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {result.student.lastName}
                    </TableCell>
                    <TableCell align="center">{result.student.name}</TableCell>
                    <TableCell align="center">{result.student.school}</TableCell>
                    <TableCell align="center">{result.student.city}</TableCell>
                    <TableCell align="center">{result.percentageResult.toFixed(2)}</TableCell>
                    {!isAsTeacher && <TableCell align="center">{result.token}</TableCell>}
                    {isAsTeacher && result.course && (
                      <TableCell align="center">
                        <BgiLink to={`/courses/${result.course.id}/chapters`}>{result.course.title}</BgiLink>
                      </TableCell>
                    )}
                    <TableCell align="center">{dayjs(result.createdAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  </TableRow>
                </>
              )

            })}
          </TableBody>
        </Table>
      </TableContainer>


    </Container>
  );
}


export default ShowExamResult
