import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { BasicExam, Exam, ExamI, GeneratedExam } from '../types/exam'
import randomInt from '../utils/randomInt'
import { randomizeSlideAnswers } from './chaptersApi'

const transformGeneratedResponse = (data: GeneratedExam | null) => {
  if (!data) return null

  if (data) {
    if ((typeof data == "object") && 'test_case' in data)
      return data
  }

  const { slides, stickyAds, bannerAd } = data

  if (bannerAd) {
    const { type, ...restBannerAd } = bannerAd

    slides.unshift({
      type: 'banner-ad',
      incompletesIds: [],
      unavailable: false,
      note: '',
      moreInfo: '',
      ...restBannerAd,
    })
  }

  return {
    ...data,
    slides: slides.map(randomizeSlideAnswers),
    bannerAd,
    stickyAds: [...new Array(slides.length)].map((_, idx) =>
      stickyAds.length > 0 && !(bannerAd && idx === 0)
        ? stickyAds[randomInt(0, stickyAds.length) % slides.length] ?? null
        : null
    ),
  }
}

interface GetCourseExamInput {
  courseId: string
  questionsNum: number
  teacherCourseToken?: string
}

export const examsApi = createApi({
  reducerPath: 'examsApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/exams`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getExams: builder.query<BasicExam[], void>({
      query: () => '',
    }),

    getExam: builder.query<Exam | null, string>({
      query: (examId) => `/${examId}`,
    }),

    getCourseExam: builder.query<GeneratedExam | null, GetCourseExamInput>({
      query: ({ courseId, questionsNum, teacherCourseToken }) => (
        `/courses/${courseId}/questions/${questionsNum}/generated` +
        (teacherCourseToken ? `?teacherCourseToken=${teacherCourseToken}` : '')
      ),
      transformResponse: transformGeneratedResponse,
    }),
    getSpecialExam: builder.query<GeneratedExam | null, string>({
      query: (examToken) => `/special/${examToken}/generated`,
      transformResponse: transformGeneratedResponse,
    }),
    createExam: builder.mutation<Exam, ExamI>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateExam: builder.mutation<Exam, Partial<ExamI> & Pick<Exam, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),
    deleteExam: builder.mutation<void, string>({
      query: (examId) => ({ url: `/${examId}`, method: 'DELETE' }),
    }),
  }),
})

export const {
  useGetExamsQuery,
  useGetExamQuery,
  useGetCourseExamQuery,
  useGetSpecialExamQuery,
  useCreateExamMutation,
  useUpdateExamMutation,
  useDeleteExamMutation,
} = examsApi
