import { useParams } from 'react-router-dom'
import LoadingPage from '../LoadingPage'
import NotFound from '../NotFound'
import { useEffect } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { resetSlidesState } from '../../store/slides'
import { useGetCourseExamQuery } from '../../store/examsApi'
import { useGetChaptersQuery } from '../../store/chaptersApi'
import { useGetCourseQuery } from '../../store/coursesApi'
import PageSlidesView from '../slides/PageSlidesView'
import { TopLinearProgress } from '../TopLinearProgress'

interface CourseExamViewUrlParams {
  courseId: string
  questionsNum: string
  teacherCourseToken?: string
}

const CourseExamView = () => {
  const {
    courseId,
    questionsNum: questionsNumParam,
    teacherCourseToken
  } = useParams() as unknown as CourseExamViewUrlParams

  const questionsNum = Number(questionsNumParam);

  const {
    isFetching: isFetchingExam,
    data: exam,
    error: fetchExamErr,
  } = useGetCourseExamQuery({ courseId, questionsNum, teacherCourseToken })

  const {
    isFetching: isFetchingCourse,
    data: course,
    error: fetchCourseErr,
  } = useGetCourseQuery(courseId)

  const {
    isFetching: isFetchingChapters,
    data: chapters,
    error: fetchChaptersErr,
  } = useGetChaptersQuery(courseId)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetSlidesState())
  }, [])

  if (isFetchingExam || isFetchingCourse || isFetchingChapters) {
    return <TopLinearProgress />
  }

  if (fetchExamErr || fetchCourseErr || fetchChaptersErr) {
    return (
      <p style={{ textAlign: 'center' }}>
        Please check your internet connection
      </p>
    )
  }

  if (!exam || !course || !chapters) return <NotFound />

  const { slides, units, filesHashes, stickyAds, bannerAd } = exam

  if (slides.length === 0) {
    return <p style={{ textAlign: 'center' }}>No Slides yet</p>
  }

  return (
    <PageSlidesView
      dir={course.language === 'ar' ? 'rtl' : 'ltr'}
      slides={slides}
      units={units}
      filesHashes={filesHashes}
      course={course}
      courseChapters={chapters}
      isCurExam
      stickyAds={stickyAds}
      isThereBannerAd={!!bannerAd}
    />
  )
}

export default CourseExamView
