import { Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


interface CopyLinkOrTextProps {
  url: string;
  nameText: string;
}

const CopyLinkOrText = ({ url, nameText }: CopyLinkOrTextProps ) => {

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        alert('تم نسخ الرابط بنجاح!');
      },
      (err) => {
        console.error('فشل النسخ: ', err);
      }
    );
  };
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}></a>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCopy}
          startIcon={<ContentCopyIcon  style={{marginLeft: '1rem'}}/>}
        >
          {nameText}
        </Button>
      </div>
    </div>
  )
}

export default CopyLinkOrText
