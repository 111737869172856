import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ExamResultFV, ExamResult } from '../types/examResult'
import { apiConfig } from './api'
// import { User } from '../types/user'

interface GetExamResultsPayload {
  isAsTeacher: boolean
}

export const examResultApi = createApi({
  reducerPath: 'examResultApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/exam-result`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['ExamResult'],
  endpoints: (builder) => ({
    getExamResults: builder.query<ExamResult[], GetExamResultsPayload>({
      query: ({ isAsTeacher=false }) => `?isAsTeacher=${isAsTeacher}`,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'ExamResult' as const, id })), 'ExamResult']
          : ['ExamResult'],
    }),

    getExamResult: builder.query<ExamResult | null, string>({
      query: (resultId) => `/${resultId}`,
    }),
    createExamResult: builder.mutation<ExamResult, ExamResultFV>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateExamResult: builder.mutation<ExamResult, Partial<ExamResultFV> & Pick<ExamResult, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),
    deleteExamResult: builder.mutation<void, string>({
      query: (resultId) => ({ url: `/${resultId}`, method: 'DELETE' }),
    }),


  }),
})

export const {
  useGetExamResultsQuery,
  useGetExamResultQuery,
  useCreateExamResultMutation,
  useUpdateExamResultMutation,
  useDeleteExamResultMutation,
} = examResultApi
