/** @jsxImportSource @emotion/react */
import { useMemo, useRef, useState } from 'react'
import { useCreateClickMutation } from '../../store/clicksApi'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGetChaptersQuery } from '../../store/chaptersApi'
import { useGetCourseQuery } from '../../store/coursesApi'
import PageHeader from '../../UI/PageHeader'
import { TopLinearProgress } from '../TopLinearProgress'
import DoorBackIcon from '@mui/icons-material/DoorBack';
import Layout from '../page/Layout'
import { axiosInstance } from '../../utils/axiosInstance'
import { ChapterSlidesData } from '../../types/slide'
import { FILES_BASE_URL } from '../../constants/FILES_BASE_URL'
import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import { useSnackbar } from 'notistack'
import getInstalledCourses from '../../utils/getInstalledCourses'
import { Alert, Button, CircularProgress, Container } from '@mui/material'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'
import { css } from '@emotion/react'
import { useEventTracking, usePageTracking } from '../../GoogleAnalytics'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import CopyLinkOrText from '../../UI/CopyLinkOrText'



// --------------------------------------------------------------------------------
// ---component--------------------------------------------------------------------
const PageCourseChaptersView = () => {
  const { t } = useTranslation(["PageC_C_V"])
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()
  usePageTracking()
  const trackEvent = useEventTracking()


  const [createClick] = useCreateClickMutation()

  const { courseId } = useParams() as { courseId: string }
  const { data: chapters } = useGetChaptersQuery(courseId)
  const { data: course } = useGetCourseQuery(courseId)


  const { enqueueSnackbar } = useSnackbar()

  const [isInstalling, setIsInstalling] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [installedNum, setInstalledNum] = useState(0)
  const installedChapters = useRef<string[]>([])
  const installedFiles = useRef<string[]>([])

  const installedCourse = useMemo(() => getInstalledCourses().find(c => c.id === courseId), [isInstalling])
  const isInstalledNeedsUpdate = installedCourse && course && installedCourse.version !== course.publishedVersion

  // --1-----------------------------------------
  if (!chapters || !course) { return <TopLinearProgress /> }
  // -----------------------------------------1--

  // --2-----------------------------------------
  const isInstallClick = async () => {

    trackEvent('Download', 'Click', isInstalledNeedsUpdate ? 'Update Study Unit' : 'Download Study Unit');

    try {
      const addClick = await createClick({ courseId });

    } catch (error) {
      console.error("Error during click creation or course installation:", error);
    }

    if (!isInstalling) {
      installCourse();
    }
  };
  // ----------------------------------------2---
  const installCourse = async () => {

    setIsInstalling(true)

    if (isPaused) {
      setIsPaused(false)
    } else {
      setInstalledNum(0)
      installedChapters.current = []
      installedFiles.current = []
    }

    try {
      await fetch('/bgi-icon.png')

      for (let i = 0; i < chapters.length; i++) {
        const chapter = chapters[i]

        if (installedChapters.current.includes(chapter.id)) continue

        const res = await axiosInstance.get(`/chapters/${chapter.id}/slides`)
        const chapterSlidesData: ChapterSlidesData = res.data
        const { filesHashes: slidesFiles, bannerAd, stickyAds } = chapterSlidesData.relations

        const files: { id: string; fileHash: string; }[] = [...slidesFiles]

        if (bannerAd) {
          files.push({ id: bannerAd.imageFile.id, fileHash: bannerAd.imageFile.fileHash })
        }

        stickyAds.forEach(stickyAd => {
          if (stickyAd) {
            files.push({ id: stickyAd.imageFile.id, fileHash: stickyAd.imageFile.fileHash })
          }
        })

        for (let j = 0; j < files.length; j++) {
          const file = files[j]

          if (installedFiles.current.includes(file.id)) continue

          await axiosInstance.get(`${FILES_BASE_URL}/${file.fileHash}`)

          installedFiles.current.push(file.id)
        }

        installedChapters.current.push(chapter.id)

        setInstalledNum(prevNum => prevNum + 1)
      }

      const installedCourses = getInstalledCourses()

      const prevInstalledCourseIdx = installedCourses.findIndex(installedCourse => installedCourse.id === courseId)

      if (prevInstalledCourseIdx > -1) {
        installedCourses.splice(prevInstalledCourseIdx, 1)
      }

      installedCourses.push({
        id: course.id,
        version: course.publishedVersion
      })

      window.localStorage.setItem('installedCourses', JSON.stringify(installedCourses))
    } catch {
      setIsPaused(true)
      enqueueSnackbar({ message: t('PageC_C_V:SureConnectedInternet'), variant: 'error' });

      // enqueueSnackbar(t('PageC_C_V:SureConnectedInternet'), { variant: 'error' });
    } finally {
      setIsInstalling(false)
    }
  }

  const questionsNumbers: number[] = [20, 30, 50];



  let teacherExamUrl: string | undefined;
  if (course?.teacherCourseToken) {
    teacherExamUrl = `${window.location.protocol}//${window.location.host}/${course.teacherCourseToken}/courses/${course.id}/questions/20/exam`;


  }



  //---return----------------------------------------------------------------
  return (
    <Layout>
      <Container maxWidth="md" css={dynamicLanguageStyle}>
        <br />
        <Button
          component={Link}
          to={`/subcollections/${course.subcollectionId}/courses`}
          variant="contained"
        >
          {t('PageC_C_V:ReturnToListOfCourse')}
        </Button>
        <div style={{ width: '100%', height: 30 }} />

        <PageHeader style={{ marginBottom: 24 }} title={`${t('Course')}: ${course.title}`} />

        {chapters.length > 0 ? (
          <div>
            <>
              {(course.isDownloadVisible) ? (
                <>
                  {installedCourse && (
                    <Alert style={{ marginBottom: 16 }} severity={isInstalledNeedsUpdate ? 'warning' : 'success'}>
                      {isInstalledNeedsUpdate
                        ? t('UpdateVerSionOfCourse')
                        : t('CourseDownloadUseIt')
                      }
                    </Alert>

                  )}

                  <Button
                    startIcon={isInstalling ? <CircularProgress size={20} /> : <DownloadIcon />}
                    variant="contained"
                    onClick={isInstallClick}
                    disabled={(installedCourse && !isInstalledNeedsUpdate) || isInstalling}

                  >
                    {isPaused ? t('DownloadCompletion') : (isInstalledNeedsUpdate ? t('UpdateTheStudyUnit') : t('DownloadTheStudyUnit'))}

                    {(isInstalling || isPaused) ? ` (${installedNum} / ${chapters.length})` : ''}
                  </Button>
                </>
              ) : (<h3>{t('ThisCourseIsNotDownloadable')}</h3>)}
            </>


            <ul style={{ paddingLeft: 24 }} css={mCss.tableStyle}>
              {chapters.map((chapter) => (
                <li key={chapter.id}>
                  <Button css={mCss.chapterTitle}
                    to={`/chapters/${chapter.id}/slides`}
                    component={Link}
                    variant="text"
                    startIcon={<DoorBackIcon />}
                  >
                    <span>{chapter.title}</span>
                  </Button>
                </li>
              ))}

              <div css={mCss.evaluationButtonCss}>
                {questionsNumbers.map((questionsNum: number) => (
                  <Button
                    to={`/courses/${courseId}/questions/${questionsNum}/exam`}
                    component={Link}
                    variant="contained"
                    color="primary"
                    sx={{
                      display: 'block',
                      textAlign: 'center',
                      fontSize: '1.2rem',
                    }}
                  >
                    <span>تقييم عام للكرس</span>
                    <br />
                    <span>{questionsNum} {' '} سؤال متجدد</span> <SportsEsportsIcon style={{ fontSize: '2rem' }} />
                  </Button>
                ))}
              </div>
            </ul>

            {course.teacherCourseToken && (
              <>
                <h3>أنشى رابط المسابقة الطلابية</h3>
                <CopyLinkOrText url={teacherExamUrl as string} nameText='أنسخ الرابط ــ 20 سؤال' />

                <br />
                <div>
                  <a href="/exam-generated-by-teacher/student-results" target="_blank" rel="noopener noreferrer"></a>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/exam-generated-by-teacher/student-results"
                    target="_blank"
                    rel="noopener noreferrer"
                    component="a"

                  >
                    أطلع على النتائج
                  </Button>
                </div>
                {/* <a href="/exam-generated-by-teacher/student-results" target="_blank"> Students Results of All Courses </a> */}
              </>
            )}
          </div>
        ) : (
          <p>{t('NoChapterAvailableYet')}</p>
        )}

        <div style={{ width: '100%', height: 30 }} />
      </Container>
      <div style={{ height: '15rem' }} />
    </Layout>
  )
}

export default PageCourseChaptersView

const mCss = {
  tableStyle: css({
    listStyleType: 'none',
    'li': {
      textDecoration: 'none',
      padding: '0.1rem',
    }
  }),
  chapterTitle: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'revert',
    border: '1px solid rgb(34, 111, 199)',
    borderRadius: '0.7rem',
    padding: '1rem',
    margin: '0.5rem 0',
    minWidth: '50%',
    maxWidth: '50%',
    color: 'black',
    'span': {
      fontSize: '1.2rem',
    },
    '& .MuiButton-startIcon': {
      margin: '0 1rem',
    },
    '@media only screen and (max-width: 760px)': {
      minWidth: '100%',
      maxWidth: '100%',
    },
    '&:hover': {
      backgroundColor: '#ffcc99',
      color: 'black',
    },
  }),
  evaluationButtonCss: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    margin: '1rem 0',
    width: '100%',
    '> *:nth-child(odd):last-child': {
      gridColumn: '1 / -1',
      justifySelf: 'center',
      width: '100%',
    },
    '@media only screen and (max-width: 760px)': {
      gridTemplateColumns: '1fr',
    },
  }),
  teacherExamUrlCss: css({
    color: 'blue',
    backgroundColor: '#1976d2',
    borderRadius: '0.5rem',
    height: '2.5rem',
  })
}


